import {
  SET_CAMPANA_DATA_RELIV,
  CAT_CAMPANA_SOLICITA_RELIV,
  CAT_CAMPANA_ERROR_RELIV,
  SET_CENTRO_MEDICO_DATA_RELIV,
  SET_AREAS_DATA_RELIV,
  SET_DOCTORES_DATA_RELIV,
  SET_TIPO_CONSULTA_DATA_RELIV,
  SET_ESTADO_CONSULTA_DATA_RELIV,
  SET_ASEGURADORAS_DATA_RELIV,
  SET_TIPO_SERVICIO_DATA_RELIV,
} from '../../@jumbo/constants/ActionTypes';

const initialState = {
  campanaData: [],
  campanaCargado: false,
  campanaError: false,
  columnas: [
    { key: 1, title: `{{IDENTIFICACION}}` },
    { key: 2, title: '{{NOMBRES_PACIENTE}}' },
    { key: 3, title: '{{PNOMBRE_PACIENTE}}' },
    { key: 4, title: '{{PAPELLIDO_PACIENTE}}' },
    { key: 5, title: '{{SAPELLIDO_PACIENTE}}' },
    { key: 6, title: '{{DENOMINACION_PACIENTE}}' },
    { key: 7, title: '{{ARTICULO_PACIENTE}}' },
    { key: 8, title: '{{SEXO_PACIENTE}}' },
    { key: 9, title: '{{FECHA_NACIMIENTO}}' },
    { key: 10, title: '{{EDAD}}' },
    { key: 11, title: '{{EMAIL}}' },
    { key: 12, title: '{{CELULAR}}' },
    { key: 13, title: '{{CENTRO_MEDICO}}' },
    { key: 14, title: '{{AREA}}' },
    { key: 15, title: '{{ARTICULO_DOCTOR}}' },
    { key: 16, title: '{{NOMBRE_DOCTOR}}' },
    { key: 17, title: '{{APELLIDO_DOCTOR}}' },
    { key: 18, title: '{{FECHA_ATENCION}}' },
    { key: 19, title: '{{DIAS_ESPERA}}' },
    { key: 20, title: '{{ASEGURADORA}}' },
    { key: 21, title: '{{TIPO_SERVICIO}}' },
  ],
  centrosmedicos: [],
  areas: [],
  doctores: [],
  tipoConsulta: [],
  estadosConsulta: [],
  aseguradoras: [],
  tiposServicios: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    //CAMPANAS
    case SET_CAMPANA_DATA_RELIV: {
      return {
        ...state,
        campanaData: action.payload,
        campanaCargado: false,
        campanaError: false,
      };
    }

    case CAT_CAMPANA_SOLICITA_RELIV: {
      return {
        ...state,
        campanaCargado: true,
        campanaError: false,
      };
    }

    case CAT_CAMPANA_ERROR_RELIV: {
      return {
        ...state,
        campanaCargado: false,
      };
    }

    //CENTROS MEDICOS
    case SET_CENTRO_MEDICO_DATA_RELIV: {
      return {
        ...state,
        centrosmedicos: action.payload,
        campanaCargado: false,
      };
    }

    //AREAS
    case SET_AREAS_DATA_RELIV: {
      return {
        ...state,
        areas: action.payload,
      };
    }

    //DOCTORES
    case SET_DOCTORES_DATA_RELIV: {
      return {
        ...state,
        doctores: action.payload,
      };
    }

    //TIPOS CONSULTA
    case SET_TIPO_CONSULTA_DATA_RELIV: {
      return {
        ...state,
        tipoConsulta: action.payload,
      };
    }

    //ESTADOS CONSULTA
    case SET_ESTADO_CONSULTA_DATA_RELIV: {
      return {
        ...state,
        estadosConsulta: action.payload,
      };
    }

    //ASEGURADORAS
    case SET_ASEGURADORAS_DATA_RELIV: {
      return {
        ...state,
        aseguradoras: action.payload,
      };
    }

    //TIPOS DE SERVICIO
    case SET_TIPO_SERVICIO_DATA_RELIV: {
      return {
        ...state,
        tiposServicios: action.payload,
      };
    }

    default:
      return state;
  }
};
