export const URL = process.env.REACT_APP_URL_MODULO_AGENDAMIENTO;
export const URL_ESPECIALIDADES = URL + '/api/specialties';
export const URL_DOCTORES = URL + '/api/doctors?LITE=1';
export const URL_REPORTES_CITAS = URL + '/api/reportesPacientes';
export const URL_REPORTES_PAGOS = URL + '/api/reportesPagos';

export const URL_CLINICAS = URL + '/api/clinics';
export const URL_SITIOS = URL + '/api/allSpots';

let URL_MICRO_TAREAS_PROGRAMADAS = process.env.REACT_APP_URL_MICRO_TAREAS_PROGRAMADAS;
export const URL_TAREAS_PROGRAMADA = URL_MICRO_TAREAS_PROGRAMADAS + '/api/availableServices';
export const URL_CREAR_TAREA_PROGRAMADA = URL_MICRO_TAREAS_PROGRAMADAS + '/api/createScheduledTasks';
export const URL_TAREAS_ACTIVAS = URL_MICRO_TAREAS_PROGRAMADAS + '/api/activeTasks';
export const PATH_TAREAS_ACTIVAS = '/tareasProgramadas/tareas';

//V2
export const URL_ESPECIALIDADES_V2 = URL + '/mv/api/specialties';
export const URL_SITIOS_V2 = URL + '/mv/api/clinics';
export const URL_DOCTORES_V2 = URL + '/mv/api/doctors';
//CLIENTE
export const URL_VERIFICAR_CLIENTE = URL + '/mv/api/client';
export const URL_UPDATE_CLIENTE = URL + '/mv/api/client/update';

export const URL_AGENDAMIENTO = URL + '/mv/api/appointment';
export const URL_CONFIRMAR_AGENDAMIENTO = URL + '/mv/api/confirm_appointment';
export const URL_HORARIOS = URL + '/mv/api/schedules';
export const URL_ASEGURADORAS_V2 = URL + '/mv/api/insurers';
export const URL_AGENDAS_V2 = URL + '/mv/api/avaible';

// URL 4 restricciones
export const URL_VERIFICA_AGENDA = URL + '/mv/api/verificar_agenda';
export const URL_VERIFICA_DOCTORES = URL + '/mv/api/medicosInfo';
export const URL_VERIFICA_SEXO = URL + '/mv/api/especialidadres';
export const URL_RESTRICCION_ASEGURADORA = URL + '/mv/api/aseguradorares';

export const URL_SCHEDULE_PUT = URL + '/api/schedules'; //Sirve para actualizar
export const URL_ASEGURADORAS = URL + '/api/insurers';
export const URL_CONSULTA_PLAN = URL + '/api/consult_plan_V2';
export const URL_CONSULTA_COPAGO = URL + '/api/consult_copago_v2';
export const URL_OTRAS_ASEGURADORAS = URL + '/mv/api/find_other_Insurance';

//ROLES
export const URL_SECCIONES = URL + '/mv/api/secciones';
export const URL_ROLES_SECCIONES = URL + '/mv/api/roles_secciones';
export const URL_ENCONTRAR_ROL = URL + '/mv/api/encontrar_rol';

//TEST
export const URL_GET_GOOD_DELAY = URL + '/api/test/getGoodDelay';
export const URL_POST_GOOD_DELAY = URL + '/api/test/postGoodDelay';
export const URL_GET_BAD_DELAY = URL + '/api/test/getBadDelay';
export const URL_POST_BAD_DELAY = URL + '/api/test/postBadDelay';

//PERMISOS
export const URL_ENCONTRAR_PERMISOS_COMPONENTE = URL + '/mv/api/permisos_componente';

//REPORTES
export const URL_REPORTES_CITAS_V2 = URL + '/mv/api/reportesPacientes';
export const URL_NOTIFICACIONES_v2 = URL + '/mv/api/notificacionesTelemedicina';
// 2022-12-09
export const URL_REPORTE_CITAS = URL + '/mv/api/rep_citas';

// SYNLAB
export const URL_SYNLAB_GET_ORDENES = URL + '/mv/api/synlab/ordenes';
export const URL_SYNLAB_GET_RESULTADOS = URL + '/mv/api/synlab/resultados';
// CAMPANAS
export const URL_CAMPANAS_V2 = URL + '/mv/api/rep_campaigns';
// VITALITY
export const URL_SYNLAB_GET_INFO_VITALITY = URL + '/mv/api/vitality/cliente';
export const URL_SYNLAB_POST_RESULTADOS = URL + '/mv/api/vitality/examenes';
export const URL_SYNLAB_GET_INFO_EXAMEN = URL + '/mv/api/vitality/examen';

//PRODUBANCO
export const URL_CLIENTE_PRODUBANCO = URL + '/mv/api/clienteProdu';
export const URL_PRODUBANCO_POST_RESULTADOS = URL + '/mv/api/produbanco/resultados';

//RELIV
export const URL_CAMPANAS_RELIV = URL + '/mv/api/reliv/rep_campana';
export const URL_CENTROSM_RELIV = URL + '/mv/api/reliv/cm';
export const URL_ESPECIALIDADES_RELIV = URL + '/mv/api/reliv/especialidades';
export const URL_AREAS_RELIV = URL + '/mv/api/reliv/areas';
export const URL_DOCTORES_RELIV = URL + '/mv/api/reliv/doctores';
export const URL_TIPOSCONSULTA_RELIV = URL + '/mv/api/reliv/tiposCita';
export const URL_TIPOSSERVICIOS_RELIV = URL + '/mv/api/reliv/tiposServicios';
export const URL_ESTADOS_RELIV = URL + '/mv/api/reliv/estadosCita';
export const URL_ASEGURADORAS_RELIV = URL + '/mv/api/reliv/aseguradoras';
